import { render, staticRenderFns } from "./identityManage.vue?vue&type=template&id=a35a569a&scoped=true"
import script from "./identityManage.vue?vue&type=script&lang=js"
export * from "./identityManage.vue?vue&type=script&lang=js"
import style0 from "./identityManage.vue?vue&type=style&index=0&id=a35a569a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a35a569a",
  null
  
)

export default component.exports