<template>
  <page-container is-show-breadcrumbs style="padding-bottom: 24px" class="custom-el-style">
    <div class="filter-box">
      <el-form
        inline
        :model="filterCondition"
        ref="filterCondition"
        size="medium"
        label-width="76px"
      >
        <el-button type="primary" @click="handleAdd"> 添加标识 </el-button>
        <el-button type="primary" style="margin-right: 100px" @click="handleRegister" plain>
          批量注册标识
        </el-button>
        <el-form-item label="标识分类：">
          <el-select
            class="el-tree-select-input"
            v-model="keyword"
            popper-class="select-option"
            ref="select"
            v-bind="selectParams"
            :popper-append-to-body="true"
            v-popover:popover
          />
          <el-popover
            ref="popover"
            popper-class="el-tree-select-popper"
            trigger="click"
            :width="340"
            v-model="isShowsearchPopover"
          >
            <el-scrollbar
              tag="div"
              wrap-class="el-select-dropdown__wrap"
              view-class="el-select-dropdown__list"
            >
              <!-- 树列表 -->
              <el-tree
                ref="tree"
                v-bind="treeParams"
                :data="treeParams.menuList"
                node-key="cat_id"
                @node-click="searchMenuInfo"
              />
            </el-scrollbar>
          </el-popover>
        </el-form-item>
        <el-form-item label="标识名称：">
          <el-input
            v-model="filterCondition.identity_name"
            placeholder="请输入标识名称"
            style="width: 210px"
          />
        </el-form-item>
        <el-form-item label="标识码：">
          <el-input
            v-model="filterCondition.identity_handle"
            placeholder="请输入标识码"
            style="width: 210px"
          />
        </el-form-item>
        <el-form-item style="margin-left: 5px">
          <el-button type="primary" @click="searchHandle">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="listData"
      :header-cell-style="{
        height: '60px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#909399',
        background: '#FAFAFA',
      }"
      style="margin-top: 16px; font-size: 14px"
    >
      <!-- <el-table-column style="padding-left:16px" prop="identity_id" label="序号" width="180" /> -->
      <el-table-column prop="identity_cat_name" label="标识分类" min-width="100" />
      <el-table-column prop="identity_name" label="标识名称" min-width="200" />
      <el-table-column prop="identity_handle" label="标识码" min-width="150">
        <template v-slot="{ row }">
          <a target="_blank" :href="row.gwtUrl" style="color: #4f7afd; text-decoration: none">{{
            row.identity_handle
          }}</a>
        </template>
      </el-table-column>
      <el-table-column label="标识二维码" min-width="80" align="center">
        <template #header>
          标识二维码
          <el-tooltip
            class="item"
            effect="dark"
            content="信息完成工网通入网及区块链上链预计需要1小时左右"
            placement="top-start"
          >
            <i style="margin-left: 5px" class="iconfont icon-shuoming" />
          </el-tooltip>
        </template>
        <template v-slot="{ row }">
          <span
            class="iconfont icon-ico"
            v-if="row.identity_enter_status == 1 || row.isUpChain"
            @click="preview(row)"
          />
          <span style="color: #636363" v-else-if="row.identity_enter_status == 2">更新中</span>
          <span
            style="color: #ffa200"
            v-else-if="row.identity_enter_status == 0 && row.isUpChain == 0"
            >注册中</span
          >
        </template>
      </el-table-column>
      <el-table-column min-width="80" width="150" align="left" label="操作">
        <template #header>
          操作
          <el-tooltip
            class="item"
            effect="dark"
            content="非手动添加的标识自动更新，不可编辑"
            placement="top-start"
          >
            <i style="margin-left: 5px" class="iconfont icon-shuoming" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.identity_type == 0"
            style="padding: 10px 16px 10px 0"
            type="text"
            @click="identityIdUpdate(scope.row.identity_id)"
          >
            编辑
          </el-button>
          <el-button
            v-else-if="scope.row.identity_type == 3"
            style="padding: 10px 16px 10px 0"
            type="text"
            @click="editAsset(scope.row.identity_id)"
          >
            编辑
          </el-button>
          <span v-else>- -</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <pagination
        :page-index="filterCondition.pageIndex"
        :page-size="filterCondition.pageSize"
        :total="total"
        @sizechange="handleSizeChange"
        @currentchange="handleCurrentChange"
        style="margin-top: 24px"
      />
    </el-row>
    <el-dialog
      :title="isUpdate ? '编辑标识' : '添加标识'"
      center
      width="30%"
      :visible.sync="isShowDialog"
    >
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="标识分类：" prop="selectMenu" :label-width="formLabelWidth">
          <div style="display: flex">
            <el-select
              class="el-tree-select-input"
              v-model="form.selectMenu"
              popper-class="select-option"
              ref="select"
              v-bind="selectParams"
              :popper-append-to-body="true"
              v-popover:popover
            />
            <el-button type="text" style="padding: 0" @click="linkTo('/manage/category')"
              >添加分类</el-button
            >
          </div>
          <el-popover
            ref="popover"
            popper-class="el-tree-select-popper"
            trigger="click"
            :width="340"
            v-model="isShowPopover"
          >
            <el-scrollbar
              tag="div"
              wrap-class="el-select-dropdown__wrap"
              view-class="el-select-dropdown__list"
            >
              <!-- 树列表 -->
              <el-tree
                ref="tree"
                v-bind="treeParams"
                :data="treeParams.menuList.filter((item) => item.cat_type == 0)"
                node-key="cat_id"
                @node-click="selectMenuInfo"
              />
            </el-scrollbar>
          </el-popover>
          <div class="tip">添加自定义标识需要先在标识分类中添加自定义分类</div>
        </el-form-item>
        <el-form-item label="标识名称：" prop="identity_name" :label-width="formLabelWidth">
          <el-input style="width: 250px" v-model="form.identity_name" autocomplete="off" />
        </el-form-item>
        <el-form-item
          v-if="!isUpdate"
          label="标识码："
          :label-width="formLabelWidth"
          prop="identity_handle"
          :rules="[
            {
              min: 4,
              max: 32,
              message: '最低长度不能低于4位,标识码只能是数字和字母组合',
              trigger: 'change',
            },
            { required: !form.is_handle, message: '必填', trigger: 'change' },
          ]"
        >
          <span>{{ company_node }}</span>
          <el-input
            maxlength="32"
            :disabled="form.is_handle"
            style="width: 160px; margin: 0 10px"
            v-model="form.identity_handle"
          />
          <!-- <el-input v-else maxlength="32" :disabled="form.is_handle" style="width:160px;margin: 0 10px;"
                    v-model="identity_handle_info"
          /> -->
          <el-checkbox :disabled="isUpdate" v-model="form.is_handle">随机标识</el-checkbox>
        </el-form-item>
        <el-form-item v-else label="标识码：" :label-width="formLabelWidth" prop="identity_handle">
          <span>{{ company_node }}</span>
          <el-input
            maxlength="32"
            :disabled="form.is_handle"
            style="width: 160px; margin: 0 10px"
            v-model="identity_handle_info"
          />
          <el-checkbox :disabled="isUpdate" v-model="form.is_handle">随机标识</el-checkbox>
        </el-form-item>
        <el-form-item label="数据模板：" prop="tpl_id" :label-width="formLabelWidth">
          <div style="display: flex">
            <el-select
              @change="getField"
              @visible-change="visibleChange"
              v-model="form.tpl_id"
              placeholder="请选择数据模板"
            >
              <el-option
                v-for="item in dataList"
                :label="item.tpl_name"
                :value="item.tpl_id"
                :key="item.tpl_id"
              />
            </el-select>
            <el-button type="text" style="padding: 0" @click="linkTo('/manage/patternplate/add')"
              >添加模版</el-button
            >
          </div>

          <div class="tip">添加自定义标识需要先在数据模板中添加自定义数据模板</div>
        </el-form-item>
        <el-form-item
          :label="item.attr_name + '：'"
          :prop="'values.' + index + '.attr_value'"
          :rules="[
            { required: item.attr_required == 1 ? true : false, message: '必填', trigger: 'blur' },
          ]"
          v-for="(item, index) in form.values"
          :key="item.attr_id"
          :label-width="formLabelWidth"
        >
          <el-input style="width: 250px" v-model="item.attr_value" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clearable">取 消</el-button>
        <el-button type="primary" @click="submit">{{ isUpdate ? "编 辑" : "提 交" }}</el-button>
      </div>
    </el-dialog>

    <!-- 预览下载工网通、上链二维码 -->
    <qr-code-dialog
      @close="dialogClose"
      :identity-id="identityId"
      :identity-type="identityType"
      :value="dialogVisible"
      :is-up-chain="isUpChain"
      :gwt-qrcode="gwtQrcode"
      :gwt-url="gwtUrl"
    />
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import qrCodeDialog from "@/components/common/qrCode.vue";
import { identityApi, identifierApi, templateApi } from "@/services/api";
export default {
  components: {
    PageContainer,
    qrCodeDialog,
  },
  data() {
    return {
      dialogVisible: false,
      isUpChain: 0, // 该数据是否上链
      identityId: 0, // 标识id
      identityType: 0, // 标识类型
      gwtQrcode: "", // 标识二维码路径
      gwtUrl: "", // 标识二维码数据流
      keyword: "",
      baseCode: "",
      company_node: "",
      isShowPopover: false,
      isShowsearchPopover: false,
      identity_handle_info: "",
      tpl_idUpdate: "",
      treeParams: {
        clickParent: true,
        filterable: false,
        menuList: [],
        props: {
          children: "cat_child",
          label: "cat_name",
          value: "cat_id",
        },
      },
      selectParams: {
        multiple: false,
        clearable: false,
        placeholder: "请选择标识分类",
      },
      isShowDialog: false,
      formLabelWidth: "150px",
      rules: {
        identity_name: [{ required: true, message: "请填写标识名称", trigger: "blur" }],
        tpl_id: [{ required: true, message: "请选择数据模板", trigger: "change" }],
        selectMenu: [{ required: true, message: "请选择标识分类", trigger: ["blur", "change"] }],
      },
      form: {
        selectMenu: "",
        cat_id: "",
        identity_id: false,
        tpl_id: "",
        identity_template_name: "",
        identity_handle: "",
        identity_name: "",
        values: [],
        updateValue: [],
        is_handle: true,
      },
      filterCondition: {
        pageIndex: 1,
        pageSize: 10,
        identity_name: "",
        cat_id: "",
        identity_dapp_id: "",
        identity_handle: "",
      },
      total: 0,
      dataList: [],
      checked: false,
      isUpdate: false,
      listData: [],
    };
  },
  created() {
    this.getCategory();
    this.company_node = this.$store.state.company_node;
  },
  mounted() {},
  watch: {
    isShowDialog(newValue, oldValue) {
      if (!this.isShowDialog) {
        this.clearable();
      }
    },
    isShowPopover(newValue, oldValue) {
      if (newValue) {
        this.getCategory();
      }
    },
  },
  methods: {
    async getList() {
      let { dataList, pageInfo } = await identityApi.list(this.filterCondition);
      this.listData = dataList;
      this.total = pageInfo.total;
      console.log(dataList);
    },
    searchMenuInfo(row) {
      this.keyword = row.cat_name;
      this.filterCondition.cat_id = row.cat_id;
      this.isShowsearchPopover = false;
      console.log(this.form);
    },
    selectMenuInfo(row) {
      this.form.selectMenu = row.cat_name;
      this.form.cat_id = row.cat_id;
      this.isShowPopover = false;
      console.log(this.form);
    },
    async getCategory() {
      let { data } = await identifierApi.list();
      let { dataList } = await templateApi.list();
      this.dataList = dataList;
      let allCateory = {
        cat_name: "所有分类",
        cat_id: "",
        cat_type: 1,
      };
      data.unshift(allCateory);
      console.log(data);
      this.treeParams.menuList = data;
      this.getList();
    },
    visibleChange(val) {
      this.getCategory();
    },
    async getField(id) {
      let { data } = await templateApi.detail({ id });
      console.log(data);
      this.form.values = [];
      if (this.isUpdate && this.tpl_idUpdate == id) {
        this.form.values = this.form.updateValue;
      } else {
        data.items.forEach((item, index) => {
          this.form.values.push({
            attr_value: "",
            attr_name: item.attr_name,
            attr_required: item.attr_required,
            attr_id: item.attr_id,
          });
        });
      }
      console.log(this.form.values);
    },
    searchHandle() {
      (this.filterCondition.pageIndex = 1), this.getList();
    },
    handleAdd() {
      this.isShowDialog = true;
      this.isUpdate = false;
    },
    download(row) {
      console.log(row);
    },
    handleRegister() {
      this.$router.push("/manage/identity/bulkregister");
    },
    handleSizeChange(val) {
      this.filterCondition.pageSize = val;
      console.log(this.filterCondition);
      this.getList();
    },
    handleCurrentChange(val) {
      this.filterCondition.pageIndex = val;
      this.getList();
    },
    async identityIdUpdate(identity_id) {
      let { data } = await identityApi.detail({ identity_id });
      this.form.values = [];
      console.log(data);
      this.form.selectMenu = data.identity_cat_name;
      this.form.cat_id = data.identity_cat_id; // 分类id
      this.form.identity_name = data.identity_name; // 标识名称
      this.form.identity_template_name = data.identity_template_name; // 模板名称
      this.form.identity_handle = data.identity_handle; // 标识码
      this.form.identity_id = data.identity_id; // 标识id
      this.tpl_idUpdate = this.form.tpl_id = data.identity_tpl_id; // 模板id
      this.identity_handle_info = data.identity_handle_info; // 模板id
      data.identity_attr_info.forEach((item, index) => {
        this.form.values.push({
          attr_value: item.attrv_value,
          attr_name: item.attr_name,
          attr_required: item.attr_required,
          attr_id: item.attr_id,
          attrv_id: item.attrv_id,
        });
      });
      this.isShowDialog = true;
      this.isUpdate = true;
      this.form.updateValue = this.form.values;
      console.log(this.form.updateValue);
    },
    editAsset(id) {
      this.$router.push(`/manage/identification/edit/${id}`);
    },
    linkTo(url) {
      window.open(url);
    },
    clearable() {
      this.form = {
        cat_id: "",
        identity_id: "",
        identity_handle: "",
        identity_name: "",
        values: [],
        is_handle: true,
      };
      this.$refs["form"].resetFields();
      this.isShowDialog = false;
      this.form.selectMenu = "";
    },
    submit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.isUpdate) {
            let { code, message } = await identityApi.update(this.form);
            this.getShow(code, message);
          } else {
            let { code, message } = await identityApi.register(this.form);
            this.getShow(code, message);
          }
        }
      });
      console.log(this.form);
    },
    getShow(code, message) {
      if (code != 200) {
        this.$message.error(message);
      } else {
        this.isShowDialog = false;
        this.isUpdate = false;
        this.clearable();
        this.getList();
        this.$message.success(this.isUpdate ? "编辑成功" : "添加成功");
      }
    },
    preview(row) {
      console.log("row", row);
      this.gwtQrcode = row.identity_code_data;
      this.gwtUrl = row.identity_code;
      this.identityId = row.identity_id;
      this.identityType = row.identity_type;
      this.isUpChain = row.isUpChain;
      this.dialogVisible = true;
    },
    dialogClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.filter-box {
  padding: 24px 20px 6px;
  background: var(--table-header-background);
  /deep/.el-table__body {
    font-size: 14px !important;
  }
}
/deep/.el-table__cell .cell {
  padding-left: 16px !important;
}
.table-state {
  .iconfont {
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
    color: #7b7e8c;
  }
}
/deep/.el-tree-node__content {
  height: 34px !important;
  line-height: 34px;
}
.el-form {
  /deep/ .el-form-item {
    .el-form-item__label {
      padding-right: 0;
    }
  }
  /deep/.el-select .el-input {
    width: 250px !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-radius: 4px;
  }
  .el-dialog__body {
    padding: 14px 24px;
  }
  .el-dialog__footer {
    padding-right: 24px;
    border-radius: 4px;
    padding-bottom: 24px;
    .el-button {
      width: 60px;
      height: 32px;
      padding: 8px 0;
      text-align: center;
    }
  }
}
.download {
  display: flex;
  align-items: flex-end;
  .download_btn {
    color: #0f40f7;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
  }
}
/deep/.el-input--mini .el-input__inner {
  height: 32px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  height: 30px;
}
.tip {
  display: flex;
  margin-left: 0;
  font-size: 14px;
  color: #7b7e8c;
  flex-direction: column;
  line-height: 30px;
}
.icon-ico {
  font-size: 22px;
  cursor: pointer;
  color: #636363 !important;
}
</style>
